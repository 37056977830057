/* eslint-disable @typescript-eslint/no-explicit-any */
import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";

export function createIconDefinition({
	prefix,
	iconName,
	width = 24,
	height = 24,
	ligatures = [],
	unicode = "",
	path,
}: {
	prefix: "pfg" | "pfgs" | "pfgo";
	iconName: string;
	width?: number;
	height?: number;
	ligatures?: string[];
	unicode?: string;
	path: string | string[];
}): IconDefinition {
	return {
		prefix: prefix as any,
		iconName: iconName as any,
		icon: [width, height, ligatures, unicode, path],
	};
}
